import React, { useState, useEffect } from "react";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import { getRideList } from "../api/dashboardAPI";
import { getVeichlesTypeListNotPaged } from "../api/dashboardAPI";
import { Link } from "react-router-dom";
import Modal from "react-modal";

// Configura il root element per React Modal
Modal.setAppElement("#root");

const Rides = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rides, setRides] = useState([]);
  const [veichlesType, setVeichlesType] = useState([]);
  const { token } = useSelector((state) => state.user);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [addressFrom, setAddressFrom] = useState("");
  const [addressTo, setAddressTo] = useState("");
  const [state, setState] = useState("");
  const [selectedVeichleType, setSelectedVeichleType] = useState("");

  useEffect(() => {
    setLoading(true);
    getRideList(token, page + 1).then((res) => {
      setRides(res.data);
      setPageCount(res.meta.last_page);
      setLoading(false);
    });
    getVeichlesTypeListNotPaged(token).then((res) => {
      setVeichlesType(
        res.data.map((type) => ({
          name: type.title,
          value: type.id,
        }))
      );
    });
  }, [page, token]);

  useEffect(() => {
    fetchFilteredRides();
  }, [addressFrom, addressTo, selectedVeichleType, state, page]);

  const fetchFilteredRides = () => {
    setLoading(true);
    const payload = {
      from_address: addressFrom || null,
      to_address: addressTo || null,
      vehicle_type_id: selectedVeichleType || null,
      states: state ? [state] : null,
    };

    getRideList(token, page + 1, payload).then((res) => {
      setRides(res.data);
      setPageCount(res.meta.last_page);
      setLoading(false);
    });
  };

  const handlePageChange = (selectedObject) => {
    setPage(selectedObject.selected);
  };

  const resetFilters = () => {
    setAddressFrom("");
    setAddressTo("");
    setState("");
    setSelectedVeichleType("");
  };

  const isFilterApplied = () => {
    return addressFrom || addressTo || state || selectedVeichleType;
  };

  const stateOptions = [
    {
      name: "Accepted",
      value: "Accepted",
    },
    {
      name: "Cancelled And Forwareded",
      value: "CancelledAndForwareded",
    },
    {
      name: "Finalized",
      value: "Finalized",
    },
    {
      name: "Not Finalized",
      value: "NotFinalized",
    },
    {
      name: "Published",
      value: "Published",
    },
    {
      name: "Ready",
      value: "Ready",
    },
    {
      name: "Started",
      value: "Started",
    },
    {
      name: "Finished",
      value: "Finished",
    },
    {
      name: "Cancelled",
      value: "Cancelled",
    },
  ];

  const openModalInfo = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <h1 className="title">Corse</h1>

      {/* Filtri */}
      <div className="filters">
        <div className="filter">
          <div>
            <label>Percorso corsa:</label>
            <div className="filter-date">
              <div>
                <span style={{ fontSize: "14px", marginRight: "5px" }}>
                  Da:
                </span>
                <input
                  type="text"
                  value={addressFrom}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setAddressFrom(e.target.value);
                  }}
                />
              </div>
              <div>
                <span style={{ fontSize: "14px", marginRight: "5px" }}>A:</span>
                <input
                  type="text"
                  value={addressTo}
                  onChange={(e) => setAddressTo(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
            <label>Tipo veicolo:</label>
            <select
              value={selectedVeichleType}
              onChange={(e) => setSelectedVeichleType(e.target.value)}
              style={{ marginTop: "10px" }}
            >
              <option value="">Seleziona...</option>
              {veichlesType.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "end",
                height: "25px",
              }}
            >
              <label style={{ marginBottom: 0, marginTop: 0 }}>Stato:</label>
              <div className="svg-container" onClick={openModalInfo}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-info-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                </svg>
              </div>
              <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Informazioni sugli Stati"
                style={{
                  overlay: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
                  content: {
                    width: "40%",
                    height: "fit-content",
                    margin: "auto",
                    padding: "20px",
                  },
                }}
              >
                <h2 style={{ color: "#000" }}>Legenda Stati Corse</h2>
                <hr />
                <ul style={{ color: "#000", marginTop: '25px' }}>
                  
                  <li className="state-infos">
                    <strong>Ready:</strong> La corsa è pronta per essere svolta.
                  </li>
                  <li className="state-infos">
                    <strong>Started:</strong> La corsa è in corso.
                  </li>
                  <li className="state-infos">
                    <strong>Finished:</strong> La corsa è finita.
                  </li>
                  <li className="state-infos">
                    <strong>Cancelled:</strong> La corsa è stata cancellata.
                  </li>
                  <li className="state-infos">
                    <strong>Cancelled And Forwareded:</strong>La corsa è stata cancellata è inoltrata ad altri NCC.
                  </li>
                  <li className="state-infos">
                    <strong>Finalized:</strong> Corsa appena creata. Il sistema ha assegnato la corsa ad NCC che è potenzialmente disponibile ad eseguirla.
                  </li>
                  <li className="state-infos">
                    <strong>Not Finalized:</strong> Non sono state trovate Licenze che soddisfano i requisiti di ricerca.
                  </li>
                  <li>
                    <strong>Published:</strong> La corsa è stata pagata. Si è in attesa che l'NCC ci assegni guidatore, veicolo e che la confermi.
                  </li>
                </ul>
                <button onClick={closeModal}>Chiudi</button>
              </Modal>
            </div>
            <select
              value={state}
              onChange={(e) => setState(e.target.value)}
              style={{ marginTop: "7px", height: "47px" }}
            >
              <option value="">Seleziona...</option>
              {stateOptions.map((state) => (
                <option key={state.value} value={state.value}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {isFilterApplied() && (
          <div className="reset-filters">
            <span
              className="reset-icon"
              onClick={resetFilters}
              title="Rimuovi filtri"
            >
              &#x2716;
            </span>
          </div>
        )}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <table className="user-table">
          <thead>
            <tr>
              <th>Codice corsa</th>
              <th>Da</th>
              <th>A</th>
              <th>Giorno/Ora</th>
              <th>Tipologia corsa</th>
              <th>Tipo veicolo</th>
              <th>Utente richiedente</th>
              <th>Stato</th>
              <th>Licenza</th>
            </tr>
          </thead>
          <tbody>
            {rides && rides.length > 0 ? (
              rides.map((ride) => (
                <tr key={ride.id}>
                  <td style={{ whiteSpace: "normal" }}>{ride.id}</td>
                  <td style={{ whiteSpace: "normal" }}>{ride.from_address}</td>
                  <td style={{ whiteSpace: "normal" }}>{ride.to_address}</td>
                  <td style={{ whiteSpace: "normal" }}>
                    {new Date(ride.pickup_at).toLocaleString("it-IT", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </td>
                  <td style={{ whiteSpace: "normal" }}>
                    {ride.is_live ? "Corsa immediata" : "Corsa programmata"}
                  </td>
                  <td style={{ whiteSpace: "normal" }}>
                    {ride.vehicle_type.title}
                  </td>
                  <td>
                    <Link
                      className="custom-link"
                      to={`/roles/${ride.user.id}`}
                      target="_blank"
                    >
                      {ride.user.name}
                    </Link>
                  </td>
                  <td style={{ whiteSpace: "normal" }}>{ride.state}</td>
                  
                  <td style={{ whiteSpace: "normal" }}>{ride.ncc_team ? ride.ncc_team.name : ''}</td>
                </tr>
              ))
            ) : (
              <tr>Nessun elemento trovato</tr>
            )}
          </tbody>
        </table>
      )}
      <Pagination
        pageCount={pageCount}
        onPageChange={handlePageChange}
        forcePage={page}
      />
    </>
  );
};

export default Rides;
