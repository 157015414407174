import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/LoginPage";
import { useSelector, useDispatch } from "react-redux";
import { getUserInfo } from "./store/user";
import { useEffect } from "react";
import Teams from "./pages/Teams";
import Locations from "./pages/Locations";
import Riders from "./pages/Riders";
import SingleRider from "./pages/SingleRider";
import Subscriptions from "./pages/Subscriptions";
import SingleSubscription from "./pages/SingleSubscription";
import Sidebar from "./components/Sidebar";
import Events from "./pages/Events";
import CheckoutForm from "./pages/CheckoutForm";
import "./index.css";
import { Navigate } from "react-router-dom";
import SingleEvent from "./pages/SingleEvent";
import Tags from "./pages/Tags";
import Users from "./pages/Users";
import SingleUser from "./pages/SingleUser";
import Profile from "./pages/Profile";
import Rides from "./pages/Rides"
import Reviews from "./pages/Reviews";
import Configuration from "./pages/Configuration";
import SingleTier from "./pages/SingleTier";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Reports from "./pages/Reports";
import SingleReport from "./pages/SingleReport";
import TeamNCC from "./pages/TeamNCC";
import VeichlesType from "./pages/VeichlesType";
import CreateVeichleType from "./pages/CreateVeichleType";

function App() {
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const ProtectedRoute = ({ children }) => {
    if (!token) {
      return <Navigate to="/login" replace />;
    }

    return children;
  };

  useEffect(() => {
    if (token !== null) {
      dispatch(getUserInfo());
    }
  }, [dispatch, token]);

  return (
    <div className="bigPageContainer">
      <ToastContainer theme="dark" />

      <Sidebar />
      <div className="page-container">
        <Routes>
          <Route path="login" element={<Login />} />
          <Route
            path="teams"
            element={
              <ProtectedRoute>
                <Teams />
              </ProtectedRoute>
            }
          />
          <Route
            path="teams/:id"
            element={
              <ProtectedRoute>
                <Locations />
              </ProtectedRoute>
            }
          />
          <Route
            path="nccteams/"
            element={
              <ProtectedRoute>
                <TeamNCC />
              </ProtectedRoute>
            }
          />
          <Route
            path="riders/"
            element={
              <ProtectedRoute>
                <Riders />
              </ProtectedRoute>
            }
          />
          <Route
            path="riders/:id"
            element={
              <ProtectedRoute>
                <SingleRider />
              </ProtectedRoute>
            }
          />
          <Route
            path="reviews"
            element={
              <ProtectedRoute>
                <Reviews />
              </ProtectedRoute>
            }
          />
          <Route path="subscriptions/" element={<Subscriptions />} />
          <Route
            path="subscriptions/:id"
            element={
              <ProtectedRoute>
                <SingleSubscription />
              </ProtectedRoute>
            }
          />
          <Route
            path="events/"
            element={
              <ProtectedRoute>
                <Events />
              </ProtectedRoute>
            }
          />
          <Route
            path="events/:id"
            element={
              <ProtectedRoute>
                <SingleEvent />
              </ProtectedRoute>
            }
          />
          <Route
            path="checkout/:idTeam/:idPlan"
            element={
              <ProtectedRoute>
                <CheckoutForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="tags/"
            element={
              <ProtectedRoute>
                <Tags />
              </ProtectedRoute>
            }
          />
          <Route
            path="veichles-type/"
            element={
              <ProtectedRoute>
                <VeichlesType />
              </ProtectedRoute>
            }
          />
          <Route
            path="create/veichles-type/"
            element={
              <ProtectedRoute>
                <CreateVeichleType />
              </ProtectedRoute>
            }
          />
          <Route
            path="roles/"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="roles/:idUser"
            element={
              <ProtectedRoute>
                <SingleUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="rides"
            element={
              <ProtectedRoute>
                <Rides />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="report"
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route
            path="report/:idReport"
            element={
              <ProtectedRoute>
                <SingleReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="configuration/"
            element={
              <ProtectedRoute>
                <Configuration />
              </ProtectedRoute>
            }
          />
          <Route
            path="configuration/:id"
            element={
              <ProtectedRoute>
                <SingleTier />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <Subscriptions />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
