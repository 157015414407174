import axios from "axios";
import { saveAs } from "file-saver";

const BASEURL = process.env.REACT_APP_BASEURL;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    if (status === 401 && window.location.pathname !== "/login") {
      window.location =
        window.location.protocol + "//" + window.location.host + "/login";
      localStorage.removeItem("token");
    } else {
      return Promise.reject(error); // Delegate error to calling side
    }
  }
);
export async function getAllTeams(token) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/activation/get/Team",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getActiveTeams(token, page) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/activation/get/Team/true?page=" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getActivableLocations(token) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/activation/get/Location/false",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getActivableRiders(token, page) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/activation/get/Rider/false?page=" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function createNCCTeam(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/nccteam/create",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getActiveRiders(token, page) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/activation/get/Rider/true?page=" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function getRider(token, id) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/transport/rider/get/" + id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function getEvents(token, page) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/event/validator/external/list?page=" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getEvent(token, id) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/event/get/" + id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function getMyTeams(token) {
  try {
    const response = await axios.get(BASEURL + "/api/team/user/teams", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getTeam(token, id) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/team/get/" + id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function getPlans(token) {
  try {
    const response = await axios.get(
      BASEURL + "/api/team/subscriptions/plans",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function toggleTeamActivation(token, body) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/activation/team/toggle",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
      body
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function toggleLocationActivation(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/activation/location/toggle",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function toggleRiderActivation(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/activation/rider/toggle",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function toggleVehicleActivation(token, body) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/activation/vehicle/toggle",
      body,

      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getSubscriptionInfo(token, id) {
  try {
    const response = await axios.get(
      BASEURL + "/api/team/subscriptions/current/" + id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getSubscriptionPlans(token) {
  try {
    const response = await axios.get(
      BASEURL + "/api/team/subscriptions/plans",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function buyPlan(token, body) {
  try {
    const response = await axios.post(
      BASEURL + "/api/team/subscriptions/buy",
      body,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getTags(token, page) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/tag/list?page=" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function removeTag(token, id) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/tag/delete/" + id,
      null,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function createTag(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/tag/create",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function setEventStatus(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/event/validator/external/process",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function initPayment(token, id) {
  try {
    const response = await axios.get(
      BASEURL + "/api/team/subscriptions/initPayment/" + id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getUsers(token, page, body = {}) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/user/list?page=" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: body,
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getUser(token, id) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/user/get/" + id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function exportUsers(token, body = {}) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/user/list/export/CSV",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: body,
        responseType: "blob",
      }
    );

    // Creare un Blob per il file CSV
    const blob = new Blob([response.data], { type: "text/csv" });

    // Utilizzare `file-saver` per scaricare il file
    saveAs(blob, "users.csv");
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getTables(token, id) {
  try {
    const response = await axios.get(
      BASEURL + "/api/ticketing/table/tier/get/" + id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function addRole(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/user/role/add",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function removeRole(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/user/role/remove",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function setTickeTierInfo(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/ticket/tier/validator/external/set",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function cancelSubscription(token, id) {
  try {
    const response = await axios.get(
      BASEURL + "/api/team/subscriptions/cancel/" + id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getRiderSelf(token) {
  try {
    const response = await axios.get(
      BASEURL + "/api/transport/rider/get/self",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getActivePlan(token, id) {
  try {
    const response = await axios.get(
      BASEURL + "/api/team/subscriptions/stripe/" + id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getReviews(token) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/review/validator/list",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function toggleReviewStatus(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/review/validator/process",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function getConfiguration(token) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/configuration",

      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function setConfiguration(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/configuration/set",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function getRideTiers(token) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/transport/ride/tier/list",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getRideTier(token, id) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/transport/ride/tier/get/" + id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getRideTierPricings(token, id, page) {
  try {
    const response = await axios.get(
      BASEURL +
        "/api/backoffice/transport/ride/tier/pricing/list/" +
        id +
        "?page=" +
        page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function editRideTier(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/transport/ride/tier/edit",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function createRideTierPricing(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/transport/ride/tier/pricing/create",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function editRideTierPricing(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/transport/ride/tier/pricing/edit",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function deleteRideTierPricing(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/transport/ride/tier/pricing/delete",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getValidatorReports(token, page) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/report/validator/list?page=" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getReport(token, id) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/report/get/" + id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function processReport(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/report/validator/process",
      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getProcessedReports(token, page) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/report/list/validated?page=" + page,
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getVeichlesTypeList(token, page) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/transport/vehicle-type/list?page=" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function getVeichlesTypeListNotPaged(token) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/transport/vehicle-type/list?page=all",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function createVeichleType(token, data) {
  try {
    const response = await axios.post(
      BASEURL + "/api/backoffice/transport/vehicle-type/create",
      data, 
      {
        headers: {
          Authorization: "Bearer " + token,
        }
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
//CORSE
export async function getRideList(token, page, body = {}) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/transport/ride/list?page=" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: body,
      }
    );
    return response.data;
  } catch (e) {
    if (e) throw new Error(e);
  }
}
