import React, { useState, useEffect } from "react";
import { exportUsers, getUsers } from "../api/dashboardAPI";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";
import svg from "./../assets/export.svg";

const Users = () => {
  const [users, setUsers] = useState([]);
  const { token } = useSelector((state) => state.user);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [roleFilter, setRoleFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    setLoading(true);
    getUsers(token, page + 1).then((res) => {
      setUsers(res.data);
      setPageCount(res.meta.last_page);
      setLoading(false);
    });
  }, [page, token]);

  useEffect(() => {
    fetchFilteredUsers();
  }, [roleFilter, startDate, endDate, page]);

  const fetchFilteredUsers = () => {
    setLoading(true);
    const payload = {
      created_at_from: startDate || null,
      created_at_to: endDate || null,
      signup_type_user: roleFilter ? [roleFilter] : null,
    };

    getUsers(token, page + 1, payload).then((res) => {
      setUsers(res.data);
      setPageCount(res.meta.last_page);
      setLoading(false);
    });
  };

  const handlePageChange = (selectedObject) => {
    setPage(selectedObject.selected);
  };

  const selectOptionRoles = [
    {
      name: "NCC",
      value: "NCC",
    },
    {
      name: "USER",
      value: "USER",
    },
    {
      name: "BUSINESS",
      value: "BUSINESS",
    },
  ];

  const resetFilters = () => {
    setRoleFilter("");
    setStartDate("");
    setEndDate("");
  };

  const isFilterApplied = () => {
    return roleFilter || startDate || endDate;
  };

  const onExport = () => {
    const payload = {
      created_at_from: startDate || null,
      created_at_to: endDate || null,
      signup_type_user: roleFilter ? [roleFilter] : null,
    };

    exportUsers(token, payload);
  };

  return (
    <>
      <div className="title-container">
        <h1 className="title">Utenti</h1>
        <button className="btn-custom" title="Export" onClick={onExport}>
          <img src={svg} alt="Export" width="20" height="20" />
        </button>
      </div>
      {/* Filtri */}
      <div className="filters">
        <div className="filter">
          <div>
            <label>Periodo di iscrizione:</label>
            <div className="filter-date">
              <div>
                <span style={{ fontSize: "14px", marginRight: "5px" }}>
                  Da:
                </span>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div>
                <span style={{ fontSize: "14px", marginRight: "5px" }}>A:</span>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
            <label>Ruolo utente:</label>
            <select
              value={roleFilter}
              onChange={(e) => setRoleFilter(e.target.value)}
              style={{ marginTop: "10px" }}
            >
              <option value="">Seleziona...</option>
              {selectOptionRoles.map((role) => (
                <option key={role.value} value={role.value}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {isFilterApplied() && (
          <div className="reset-filters">
            <span
              className="reset-icon"
              onClick={resetFilters}
              title="Rimuovi filtri"
            >
              &#x2716;
            </span>
          </div>
        )}
      </div>
      {/* Tabella */}
      {loading ? (
        <Loader />
      ) : (
        <table className="user-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Nome</th>
              <th>Città</th>
              <th>Dob</th>
              <th>Genere</th>
              <th>Ruolo</th>
              <th>Preferenza iscrizione</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users && users.length > 0 ? (
              users.map((user) => (
                <tr key={user.id}>
                  <td style={{ whiteSpace: "normal" }}>{user.email}</td>
                  <td>{user.name}</td>
                  <td style={{ whiteSpace: "normal" }}>{user.city}</td>
                  <td style={{ whiteSpace: "normal" }}>{user.dob}</td>
                  <td style={{ whiteSpace: "normal" }}>{user.gender}</td>
                  <td>
                    {user.role && user.role.length > 0
                      ? user.role.join(", ") // Mostra i ruoli separati da una virgola
                      : "Nessun ruolo"}
                  </td>
                  <td style={{ whiteSpace: "normal" }}>
                    {user.signup_type_user
                      ? user.signup_type_user
                      : "Nessuna preferenza"}
                  </td>
                  <td>
                    <Link to={`/roles/${user.id}`}>
                      <button className="btn">Dettagli</button>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <p style={{ color: "white", margin: '10px 0' }}>Nessun elemento trovato</p>
            )}
          </tbody>
        </table>
      )}
      <Pagination
        pageCount={pageCount}
        onPageChange={handlePageChange}
        forcePage={page}
      />
    </>
  );
};

export default Users;